import { NotificationService } from "./../../../shared/services/notification.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";
import { InstrumentService } from "./../../services/instrument.service";

import { Subscription } from "rxjs";
import { Instrument } from "./../../../shared/models/instrument";
import { OnDestroy, TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";

export enum SelectionType {
  single = "single",
  multi = "multi",
  multiClick = "multiClick",
  cell = "cell",
  checkbox = "checkbox",
}

@Component({
  selector: "ins-table",
  templateUrl: "./ins-table.component.html",
  styleUrls: ["./ins-table.component.scss"],
})
export class InsTableComponent implements OnInit, OnDestroy {

  entries: number = 10;
  selected: Instrument[] = [];
  activeRow: any;
  lastDeploy;

  clicked = false;

  subscription: Subscription;
  rows: Instrument[] = [];
  temp = [];
  id_index_Map = new Map();

  SelectionType = SelectionType;

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private InstrumentService: InstrumentService,
    private store: AngularFireStorage,
    private modalService: BsModalService,
    private notif: NotificationService
  ) {
    this.subscription = this.InstrumentService.getAll().subscribe(
      (instrument) => {
        this.rows = instrument;

        this.rows.forEach((prop, index) => {
          this.id_index_Map.set(prop["id"], index);
        });
        
        this.temp = this.rows.map((prop, key) => {
          return {
            ...prop,
            index: key,
          };
        });

        let selectedRow_id = JSON.parse(localStorage.getItem('selectedInsRow'))[0]["id"];
        let selectedIndex = this.id_index_Map.get(selectedRow_id);
        this.selected = [this.temp[selectedIndex]];
        // localStorage.clear();
      }
    );
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    localStorage.setItem('selectedInsRow',JSON.stringify(this.selected));
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnInit() {
    this.getLastDeloyTime();
  }

  // filterTable($event) {
  //   let val = $event.target.value.toLowerCase();
  //   this.temp = this.rows.filter(function(d) {

  //     return d.source.toLowerCase().indexOf(val) !== -1 || !val;
  //     // for (var key in d) {
  //     //   if (d[key].toLowerCase().indexOf(val) !== -1) {
  //     //     return true;
  //     //   }
  //     // }
  //     // return false;
  //   });
  //   // update the rows
  //   console.log(val);
  //   this.rows = this.temp;
  // }

  // for source
  // filterDatatable(filterValue) {
  //   const val = filterValue.toLowerCase();

  //   const temp = this.rows.filter(function (d) {
  //     return d.source.toLowerCase().indexOf(val) !== -1 || !val;
  //   });

  //   this.temp = temp;
  // }

  filterDatatable(filterValue) {
    const val = filterValue.toLowerCase();
    const table_cols = ["invNumber","name","type","source"];
    const n_cols = table_cols.length;

    const temp = this.rows.filter(function (item) {
      for (let i=0; i<n_cols; i++){
        let item_colVal = item[table_cols[i]];
        if (item_colVal.toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });
    this.temp = temp;  
  }

  getLastDeloyTime() {
    this.InstrumentService.getLastDeploy()
      .get()
      .subscribe((doc) => {
        if (doc.exists) {
          this.lastDeploy = doc.data()["time"];
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deploy() {
    this.InstrumentService.deploy();
    this.defaultModal.hide();
    this.notif.showNotification(
      "success",
      "Deployment Successful, Please DO NOT Deploy More Than 2 Times a Day"
    );

    this.getLastDeloyTime();
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }
}
