import { InsRepairFileComponent } from "./components/ins-repair-file/ins-repair-file.component";
import { InsRepairComponent } from "./components/ins-repair/ins-repair.component";
import { InsAlphaReportFileComponent } from "./components/ins-alpha-report-file/ins-alpha-report-file.component";
import { InsAlphaReportComponent } from "./components/ins-alpha-report/ins-alpha-report.component";
import { InsInsuranceFileComponent } from "./components/ins-insurance-file/ins-insurance-file.component";
import { InsProvenanceFileComponent } from "./components/ins-provenance-file/ins-provenance-file.component";
import { InsOwnershipFileComponent } from "./components/ins-ownership-file/ins-ownership-file.component";
import { InsBetaReportFileComponent } from "./components/ins-beta-report-file/ins-beta-report-file.component";
import { InsLoanFileComponent } from "./components/ins-loan-file/ins-loan-file.component";
import { InsMediaFileComponent } from "./components/ins-media-file/ins-media-file.component";
import { InsManagementComponent } from "./components/ins-management/ins-management.component";
import { InsInsuranceComponent } from "./components/ins-insurance/ins-insurance.component";
import { InsLoanComponent } from "./components/ins-loan/ins-loan.component";
import { InsProvenanceComponent } from "./components/ins-provenance/ins-provenance.component";
import { InsMediaComponent } from "./components/ins-media/ins-media.component";
import { InsFormComponent } from "./components/ins-form/ins-form.component";
import { InstrumentComponent } from "./containers/instrument.component";
import { Routes } from "@angular/router";
import { InsAppraisalComponent } from "./components/ins-appraisal/ins-appraisal.component";
import { InsAppraisalFileComponent } from "./components/ins-appraisal-file/ins-appraisal-file.component";

export const InstrumentRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: InstrumentComponent,
      },

      {
        path: "new",
        component: InsFormComponent,
      },

      {
        path: ":id",
        children: [
          {
            path: "",
            component: InsFormComponent,
          },
          {
            path: "media",

            children: [
              {
                path: "",
                component: InsMediaComponent,
              },
              {
                path: ":mediaId",
                component: InsMediaFileComponent,
              },
            ],
          },
          {
            path: "provenance",

            children: [
              {
                path: "",
                component: InsProvenanceComponent,
              },
              {
                path: ":provId",
                component: InsProvenanceFileComponent,
              },
            ],
          },
          {
            path: "loan",

            children: [
              {
                path: "",
                component: InsLoanComponent,
              },
              {
                path: ":loanId",
                component: InsLoanFileComponent,
              },
            ],
          },
          {
            path: "report",

            children: [
              {
                path: "",
                component: InsAlphaReportComponent,
              },
              {
                path: ":alphaReportId",
                component: InsAlphaReportFileComponent,
              },
            ],
          },
          {
            path: "management",

            children: [
              {
                path: "",
                component: InsManagementComponent,
              },
              {
                path: ":loanId",
                component: InsLoanFileComponent,
              },
              {
                path: ":betaReportId",
                component: InsBetaReportFileComponent,
              },
              {
                path: ":ownId",
                component: InsOwnershipFileComponent,
              },
            ],
          },
          {
            path: "insurance",

            children: [
              {
                path: "",
                component: InsInsuranceComponent,
              },
              {
                path: ":insuranceId",
                component: InsInsuranceFileComponent,
              },
            ],
          },
          {
            path: "repair",

            children: [
              {
                path: "",
                component: InsRepairComponent,
              },
              {
                path: ":repairId",
                component: InsRepairFileComponent,
              },
            ],
          },
          {
            path: "appraisal",

            children: [
              {
                path: "",
                component: InsAppraisalComponent,
              },
              {
                path: ":appraisalId",
                component: InsAppraisalFileComponent,
              },
            ],
          },
        ],
      },
    ],
  },
];
