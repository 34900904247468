import { InsNote } from "./../../../shared/models/ins-note";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: "ins-note",
  templateUrl: "./ins-note.component.html",
  styleUrls: ["./ins-note.component.scss"],
})
export class InsNoteComponent implements OnInit {
  insNote: InsNote = <InsNote>{};
  id;

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(private modalService: BsModalService) {}

  @ViewChild("modalNote") modalNote: TemplateRef<any>;

  ngOnInit(): void {}

  openNoteModal() {
    // this.insProv = this.selected[0];
    this.defaultModal = this.modalService.show(this.modalNote, this.default);
  }
}
