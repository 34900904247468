import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { LoginComponent } from "./components/login/login.component";

import { RouterModule } from "@angular/router";
import { CoreRoutes } from "./core.routing";
import { ProfileComponent } from './components/profile/profile.component';

@NgModule({
  declarations: [LoginComponent, ProfileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(CoreRoutes)
  ],
  exports: [LoginComponent]
})
export class CoreModule {}
