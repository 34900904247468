import { AuthGuard } from './../shared/services/auth-guard.service';
import { OrgFormComponent } from './components/org-form/org-form.component';
import { Routes } from "@angular/router";
import { OrganizationComponent } from "./containers/organization.component";

export const OrganizationRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: OrganizationComponent
      },

      {
        path:'new', 
        component:OrgFormComponent, 
      },
      
      {path:':id', 
      component:OrgFormComponent, 
      },



    ],



  }
];
