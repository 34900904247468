import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { InstrumentService } from "app/instrument/services/instrument.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ins-provenance-file",
  templateUrl: "./ins-provenance-file.component.html",
  styleUrls: ["./ins-provenance-file.component.scss"],
})
export class InsProvenanceFileComponent implements OnInit {
  insId;
  provId;

  filePath;
  storePath;
  dbPath;

  constructor(
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute
  ) {
    this.insId = this.route.snapshot.paramMap.get("id");
    this.provId = this.route.snapshot.paramMap.get("provId");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));
  }

  ngOnInit() {
    this.dbPath =
      "/instrument/" + this.insId + "/provenance/" + this.provId + "/file/";
    this.storePath =
      "/instrument/" + this.insId + "/provenance/" + this.provId + "/";
  }
}
