import { FileService } from "./../../../shared/services/file.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { SelectionType } from "./../../../pages/tables/ngxdatatables/ngxdatatables.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { InstrumentLoan } from "./../../../shared/models/ins-loan";
import { finalize, take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { InstrumentService } from "./../../services/instrument.service";
import { AuthService } from "./../../../shared/services/auth.service";
import { Component, OnInit, TemplateRef } from "@angular/core";

import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";

@Component({
  selector: "ins-loan",
  templateUrl: "./ins-loan.component.html",
  styleUrls: ["./ins-loan.component.scss"],
})
export class InsLoanComponent implements OnInit {
  appUser;
  insId;
  subscription;
  insLoan;
  onEdit = false;

  rows;
  temp = [];
  entries: number = 10;
  selected: InstrumentLoan[] = [];
  activeRow: any;
  instrument;
  SelectionType = SelectionType;

  uploadModal: BsModalRef;
  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private auth: AuthService,
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fs: FileService
  ) {
    auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
    this.insId = this.route.snapshot.paramMap.get("id");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));

    this.subscription = this.InstrumentService.getAllLoan(this.insId).subscribe(
      (data) => {
        this.rows = data;
        this.temp = this.rows.map((prop, key) => {
          return {
            ...prop,
            index: key,
          };
        });
        // this.selected = [this.temp[0]];
      }
    );
  }

  ngOnInit(): void {}

  onTableSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.insLoan = {};
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  editLoan(modalDefault: TemplateRef<any>) {
    this.onEdit = true;
    this.insLoan = this.selected[0];
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  save() {
    if (this.onEdit) {
      this.InstrumentService.updateLoan(
        this.insLoan,
        this.insId,
        this.selected[0].loanId
      );
      this.onEdit = false;
      this.defaultModal.hide();
    } else {
      this.InstrumentService.createLoan(this.insLoan, this.insId);
      this.insLoan = {};
      // this.showNotification("success");
      this.defaultModal.hide();
    }
  }

  delete() {
    let dbPath =
      "instrument/" + this.insId + "/loan/" + this.selected[0].loanId + "/";
    let storePath =
      "instrument/" + this.insId + "/loan/" + this.selected[0].loanId + "/";
    if (!confirm("Are you sure you want to delete this loan and its files?"))
      return;
    this.fs.deleteFolder(dbPath, storePath);
  }
}
