import { AuthService } from "./auth.service";
import firebase from "firebase/app";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileService {
  appUser;
  constructor(
    private db: AngularFirestore,
    private store: AngularFireStorage,
    private auth: AuthService
  ) {
    auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
  }

  getAllFile(dbPath) {
    return this.db.collection(dbPath).valueChanges({ idField: "fileId" });
  }

  deleteFile(dbPath, storePath) {
    this.db.doc(dbPath).delete();
    const storageRef = this.store.storage.ref();
    storageRef.child(storePath).delete();
  }

  updateFile(file, dbPath) {
    file["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp();
    file["lastModifyPerson"] = this.appUser.name;
    return this.db.doc(dbPath).update(file);
  }

  deleteFolder(dbPath, storePath) {
    this.deleteDb(dbPath);
    this.deleteStore(storePath);
  }

  deleteDb(dbPath) {
    this.db.doc(dbPath).delete();
    this.db
      .collection(dbPath + "file")
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  deleteStore(storePath) {
    const storageRef = firebase.storage().ref(storePath);
    storageRef.listAll().then((listResults) => {
      const promises = listResults.items.map((item) => {
        return item.delete();
      });
      Promise.all(promises);
    });
  }

  creatLinkFile(file, filePath) {
    let docRef = this.db.collection(filePath).doc();
    file["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    file["createPerson"] = this.appUser.name;
    file["public"] = true;
    docRef.set(file);
    return docRef.ref.id;
  }
}
