import { OrgTableComponent } from './components/org-table/org-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPrintModule } from 'ngx-print';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { RouterModule } from '@angular/router';
import { OrganizationService } from './services/organization.service';
import { SharedModule } from 'shared/shared.module';
import { OrgFormComponent } from './components/org-form/org-form.component';
import { OrgCardComponent } from './components/org-card/org-card.component';

import { OrganizationComponent } from './containers/organization.component';
import { NgModule } from '@angular/core';
import { OrganizationRoutes } from "./organization.routing";


@NgModule({
  declarations: [
    OrganizationComponent,
    OrgTableComponent,
    OrgCardComponent,
    OrgFormComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(OrganizationRoutes),

    
    NgxDatatableModule,
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    NgxPrintModule
  ],
  providers:[
    OrganizationService,
  ]
})
export class OrganizationModule { }
