import { switchMap } from "rxjs/operators";
import { UserService } from "./user.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { Observable, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { AppUser } from "../models/app-user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user$: Observable<firebase.User>;
  constructor(
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.user$ = afAuth.authState;
  }

  login() {
    let returnUrl = this.route.snapshot.queryParamMap.get("returnUrl") || "/";
    localStorage.setItem("returnUrl", returnUrl);
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    this.afAuth.signOut();
    location.reload();
  }

  get appUser$(): Observable<AppUser> {
    // this.getToken();
    return this.user$.pipe(
      switchMap((user) => {
        if (user) return this.userService.get(user.uid);
        return of(null);
      })
    );
  }

  getToken() {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // console.log(idToken);
        // ...
      })
      .catch(function (error) {
        // Handle error
      });
  }

  signIn(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        let returnUrl =
          this.route.snapshot.queryParamMap.get("returnUrl") || "/";
        localStorage.setItem("returnUrl", returnUrl);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  signUp(email, password) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        window.alert("You have been successfully registered!");
        console.log(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
}
