import { QuillModule } from "ngx-quill";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CpaFileComponent } from "./components/cpa-file/cpa-file.component";
import { SharedModule } from "./../shared/shared.module";
import { CpaService } from "./services/cpa.service";
import { CpaRoutes } from "./cpa.routing";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CpaComponent } from "./containers/cpa/cpa.component";
import { CpaTableComponent } from "./components/cpa-table/cpa-table.component";
import { CpaCardComponent } from "./components/cpa-card/cpa-card.component";
import { ProtocolComponent } from "./containers/protocol/protocol.component";
import { ProtocolEditorComponent } from "./components/protocol-editor/protocol-editor.component";

@NgModule({
  declarations: [
    CpaComponent,
    CpaTableComponent,
    CpaCardComponent,
    CpaFileComponent,
    ProtocolComponent,
    ProtocolEditorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(CpaRoutes),
    BsDropdownModule.forRoot(),
    SharedModule,
    QuillModule.forRoot(),
  ],
  providers: [CpaService],
})
export class CpaModule {}
