import { InstrumentRepair } from "./../../../shared/models/ins-repair";
import { Location } from "@angular/common";
import { FileService } from "./../../../shared/services/file.service";
import { InstrumentInsurance } from "./../../../shared/models/ins-insurance";
import { SelectionType } from "./../../../pages/tables/ngxdatatables/ngxdatatables.component";
import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { InstrumentService } from "./../../services/instrument.service";
import { Component, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: "app-ins-repair",
  templateUrl: "./ins-repair.component.html",
  styleUrls: ["./ins-repair.component.scss"],
})
export class InsRepairComponent implements OnInit {
  insId;
  subscription;
  insRepair;
  onEdit = false;

  rows;
  temp = [];
  entries: number = 10;
  selected: InstrumentRepair[] = [];
  activeRow: any;
  instrument;
  SelectionType = SelectionType;

  uploadModal: BsModalRef;
  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fs: FileService,
    private location: Location
  ) {
    this.insId = this.route.snapshot.paramMap.get("id");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));

    this.subscription = this.InstrumentService.getAllRepair(
      this.insId
    ).subscribe((data) => {
      this.rows = data;
      this.temp = this.rows.map((prop, key) => {
        return {
          ...prop,
          index: key,
        };
      });
      // this.selected = [this.temp[0]];
    });
  }

  ngOnInit(): void {}

  onTableSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.insRepair = {};
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  edit(modalDefault: TemplateRef<any>) {
    this.onEdit = true;
    this.insRepair = this.selected[0];
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  save() {
    if (this.onEdit) {
      delete this.insRepair["index"];
      this.InstrumentService.updateRepair(
        this.insRepair,
        this.insId,
        this.selected[0].repairId
      );
      this.onEdit = false;
      this.defaultModal.hide();
    } else {
      this.InstrumentService.createRepair(this.insRepair, this.insId);
      this.insRepair = {};
      // this.showNotification("success");
      this.defaultModal.hide();
    }
  }

  delete() {
    let dbPath =
      "instrument/" + this.insId + "/repair/" + this.selected[0].repairId + "/";
    let storePath =
      "instrument/" + this.insId + "/repair/" + this.selected[0].repairId + "/";
    if (!confirm("Are you sure you want to delete this Repair and its files?"))
      return;
    this.fs.deleteFolder(dbPath, storePath);
  }

  back(): void {
    this.location.back();
  }
}
