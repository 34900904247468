import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { InstrumentService } from "./../../services/instrument.service";
import { AuthService } from "./../../../shared/services/auth.service";
import { Component, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: "ins-management",
  templateUrl: "./ins-management.component.html",
  styleUrls: ["./ins-management.component.scss"],
})
export class InsManagementComponent implements OnInit {
  appUser;
  insId;
  instrument;

  constructor(
    private auth: AuthService,
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute
  ) {
    auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
    this.insId = this.route.snapshot.paramMap.get("id");

    if (this.insId)
      this.InstrumentService.get(this.insId)
        .pipe(take(1))
        .subscribe((data) => (this.instrument = data));
  }

  ngOnInit(): void {}
}
