import { FileBrowserComponent } from "./shared/components/file-browser/file-browser.component";

import { LoginComponent } from "./core/components/login/login.component";
import { AuthGuard } from "./shared/services/auth-guard.service";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/instrument",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },

  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: "./dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "person",
        loadChildren: "./person/person.module#PersonModule",
      },

      {
        path: "organization",
        loadChildren: "./organization/organization.module#OrganizationModule",
      },
      {
        path: "instrument",
        loadChildren: "./instrument/instrument.module#InstrumentModule",
      },
      {
        path: "cpa",
        loadChildren: "./cpa/cpa.module#CpaModule",
      },

      {
        path: "dashboards",
        loadChildren: "./pages/dashboards/dashboards.module#DashboardsModule",
      },
      {
        path: "components",
        loadChildren: "./pages/components/components.module#ComponentsModule",
      },
      {
        path: "forms",
        loadChildren: "./pages/forms/forms.module#FormsModules",
      },
      {
        path: "tables",
        loadChildren: "./pages/tables/tables.module#TablesModule",
      },
      {
        path: "maps",
        loadChildren: "./pages/maps/maps.module#MapsModule",
      },
      {
        path: "widgets",
        loadChildren: "./pages/widgets/widgets.module#WidgetsModule",
      },
      {
        path: "charts",
        loadChildren: "./pages/charts/charts.module#ChartsModule",
      },
      {
        path: "calendar",
        loadChildren: "./pages/calendar/calendar.module#CalendarModule",
      },
      {
        path: "examples",
        loadChildren: "./pages/examples/examples.module#ExamplesModule",
      },
    ],
  },

  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "examples",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "dashboard",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
