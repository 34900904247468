import { HttpClientModule } from "@angular/common/http";
import { InsAppraisalFileComponent } from "./components/ins-appraisal-file/ins-appraisal-file.component";
import { InsAppraisalComponent } from "./components/ins-appraisal/ins-appraisal.component";
import { QuillModule } from "ngx-quill";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { InsFormComponent } from "./components/ins-form/ins-form.component";
import { InsCardComponent } from "./components/ins-card/ins-card.component";
import { InstrumentService } from "./services/instrument.service";
import { NgxPrintModule } from "ngx-print";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { InstrumentRoutes } from "./instrument.routing";
import { RouterModule } from "@angular/router";
import { SharedModule } from "shared/shared.module";
import { NgModule } from "@angular/core";
import { InstrumentComponent } from "./containers/instrument.component";
import { InsTableComponent } from "./components/ins-table/ins-table.component";
import { InsMediaComponent } from "./components/ins-media/ins-media.component";

import { InsProvenanceComponent } from "./components/ins-provenance/ins-provenance.component";
import { InsLoanComponent } from "./components/ins-loan/ins-loan.component";
import { InsInsuranceComponent } from "./components/ins-insurance/ins-insurance.component";
import { InsManagementComponent } from "./components/ins-management/ins-management.component";
import { InsBetaReportComponent } from "./components/ins-beta-report/ins-beta-report.component";
import { InsMediaFileComponent } from "./components/ins-media-file/ins-media-file.component";
import { InsLoanFileComponent } from "./components/ins-loan-file/ins-loan-file.component";
import { InsOwnershipComponent } from "./components/ins-ownership/ins-ownership.component";
import { InsOwnershipFileComponent } from "./components/ins-ownership-file/ins-ownership-file.component";
import { InsBetaReportFileComponent } from "./components/ins-beta-report-file/ins-beta-report-file.component";
import { InsInsuranceFileComponent } from "./components/ins-insurance-file/ins-insurance-file.component";
import { InsProvenanceFileComponent } from "./components/ins-provenance-file/ins-provenance-file.component";
import { InsAlphaReportComponent } from "./components/ins-alpha-report/ins-alpha-report.component";
import { InsAlphaReportFileComponent } from "./components/ins-alpha-report-file/ins-alpha-report-file.component";
import { InsRepairComponent } from "./components/ins-repair/ins-repair.component";
import { InsRepairFileComponent } from "./components/ins-repair-file/ins-repair-file.component";
import { InsNoteComponent } from "./components/ins-note/ins-note.component";

@NgModule({
  declarations: [
    InstrumentComponent,
    InsTableComponent,
    InsCardComponent,
    InsFormComponent,
    InsMediaComponent,
    InsProvenanceComponent,
    InsLoanComponent,
    InsInsuranceComponent,
    InsManagementComponent,
    InsBetaReportComponent,
    InsMediaFileComponent,
    InsLoanFileComponent,
    InsOwnershipComponent,
    InsOwnershipFileComponent,
    InsBetaReportFileComponent,
    InsInsuranceFileComponent,
    InsProvenanceFileComponent,
    InsAlphaReportComponent,
    InsAlphaReportFileComponent,
    InsRepairComponent,
    InsRepairFileComponent,
    InsAppraisalComponent,
    InsAppraisalFileComponent,
    InsAlphaReportFileComponent,
    InsNoteComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(InstrumentRoutes),
    NgxDatatableModule,
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule.forRoot(),
    NgxPrintModule,
  ],
  providers: [InstrumentService],
})
export class InstrumentModule {}
