import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";


import {DashboardComponent } from "./components/dashboard.component";

import { RouterModule } from "@angular/router";
import { DashboardRoutes } from "./dashboard.routing";

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,

    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    RouterModule.forChild(DashboardRoutes)
  ],
  exports: [DashboardComponent]
})
export class DashboardModule {}
