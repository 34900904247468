import { ProtocolComponent } from "./containers/protocol/protocol.component";
import { CpaComponent } from "./containers/cpa/cpa.component";
import { AuthGuard } from "../shared/services/auth-guard.service";
import { Routes } from "@angular/router";

export const CpaRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: CpaComponent,
      },
      {
        path: "protocol",
        component: ProtocolComponent,
      },
    ],
  },
];
