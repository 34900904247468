import {AfterViewInit, Component, ViewChild} from '@angular/core';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})



export class OrganizationComponent  {

  
}
