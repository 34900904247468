//Testing Firebase Project
// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: "AIzaSyAiqUNhfQBbo7lK86E7ngwh0FQcmB3DUoA",
//     authDomain: "sydb-e1138.firebaseapp.com",
//     databaseURL: "https://sydb-e1138.firebaseio.com",
//     projectId: "sydb-e1138",
//     storageBucket: "sydb-e1138.appspot.com",
//     messagingSenderId: "72608351721",
//     appId: "1:72608351721:web:1acfbd809f9c9ec1b17778",
//     measurementId: "G-3QT9FDQJPX",
//   },
// };

// Production Firebase Project
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBmYTqjST4wSEzlOF7WljgPiWFChy5nYfo",
    authDomain: "symic-2b840.firebaseapp.com",
    databaseURL: "https://symic-2b840-default-rtdb.firebaseio.com",
    projectId: "symic-2b840",
    storageBucket: "symic-2b840.appspot.com",
    messagingSenderId: "389480965561",
    appId: "1:389480965561:web:3e728850fc7ae264184268",
    measurementId: "G-25MYZCBNN7",
  },
};
