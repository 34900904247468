import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { InstrumentService } from "./../../services/instrument.service";
import { AuthService } from "./../../../shared/services/auth.service";
import { Component, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: "app-ins-ownership-file",
  templateUrl: "./ins-ownership-file.component.html",
  styleUrls: ["./ins-ownership-file.component.scss"],
})
export class InsOwnershipFileComponent implements OnInit {
  instrument;
  insId;
  ownId;

  filePath;
  storePath;
  dbPath;

  constructor(
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute
  ) {
    this.insId = this.route.snapshot.paramMap.get("id");
    this.ownId = this.route.snapshot.paramMap.get("ownId");

    if (this.insId)
      this.InstrumentService.get(this.insId)
        .pipe(take(1))
        .subscribe((data) => (this.instrument = data));
  }

  ngOnInit() {
    this.dbPath =
      "/instrument/" + this.insId + "/ownership/" + this.ownId + "/file/";
    this.storePath =
      "/instrument/" + this.insId + "/ownership/" + this.ownId + "/";
  }
}
