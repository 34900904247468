import { OrganizationService } from './../../services/organization.service';
import { Organization } from 'shared/models/organization';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';

export enum SelectionType {
  single = "single",
  multi = "multi",
  multiClick = "multiClick",
  cell = "cell",
  checkbox = "checkbox"
}
@Component({
  selector: "org-table",
  templateUrl: "org-table.component.html"
})
export class OrgTableComponent implements OnInit, OnDestroy {
  entries: number = 10;
  selected: Organization[] = [];
  temp = [];
  activeRow: any;

  subscription: Subscription;
  rows: Organization[] = [];


  SelectionType = SelectionType;



  constructor(private OrganizationService: OrganizationService) {

    this.subscription = this.OrganizationService.getAll()
    .subscribe(organization => {
      this.rows = organization;
      this.temp = this.rows.map((prop, key) => {
        return {
          ...prop,
          index:key
        };
      });
      this.selected = [this.temp[0]];
      
    });
    
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  // filterTable($event) {
  //   let val = $event.target.value;
  //   this.temp = this.rows.filter(function(d) {
  //     for (var key in d) {
  //       if (d[key].toLowerCase().indexOf(val) !== -1) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   });
  // }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnInit() {


    

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
