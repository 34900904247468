import { NotificationService } from "./services/notification.service";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FilesizePipe } from "./pipes/filesize.pipe";

import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AuthGuard } from "./services/auth-guard.service";
import { AuthService } from "./services/auth.service";
import { UserService } from "./services/user.service";
import { CommonModule } from "@angular/common";
import { NgxDropzoneModule } from "ngx-dropzone";
import { FileBrowserComponent } from "./components/file-browser/file-browser.component";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";

@NgModule({
  declarations: [FilesizePipe, FileBrowserComponent],
  exports: [
    AngularFirestoreModule,
    AngularFireAuthModule,
    CommonModule,
    FormsModule,
    NgxDropzoneModule,
    FilesizePipe,
    ProgressbarModule,
    FileBrowserComponent,
    NgxDatatableModule,
  ],
  providers: [AuthService, AuthGuard, UserService, NotificationService],

  imports: [
    AngularFirestoreModule,
    AngularFireAuthModule,
    CommonModule,
    FormsModule,
    NgxDropzoneModule,
    NgxDatatableModule,
  ],
})
export class SharedModule {}
