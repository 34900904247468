import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ins-insurance-file",
  templateUrl: "./ins-insurance-file.component.html",
  styleUrls: ["./ins-insurance-file.component.scss"],
})
export class InsInsuranceFileComponent implements OnInit {
  insId;
  insuranceId;

  filePath;
  storePath;
  dbPath;

  constructor(private route: ActivatedRoute) {
    this.insId = this.route.snapshot.paramMap.get("id");
    this.insuranceId = this.route.snapshot.paramMap.get("insuranceId");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));
  }

  ngOnInit() {
    this.dbPath =
      "/instrument/" + this.insId + "/insurance/" + this.insuranceId + "/file/";
    this.storePath =
      "/instrument/" + this.insId + "/insurance/" + this.insuranceId + "/";
  }
}
