import { Observable } from "rxjs";
import { AppUser } from "../models/app-user";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private db: AngularFirestore) {}

  save(user: firebase.User) {
    this.db.collection("users").doc(user.uid).set(
      {
        id: user.uid,
        name: user.displayName,
        email: user.email,
      },
      { merge: true }
    );
  }

  get(uid: string): Observable<AppUser> {
    return this.db.doc<AppUser>("/users/" + uid).valueChanges();
  }

  updateSyfirRole(uid, role) {
    this.db.collection("users").doc(uid).update({ syfirRole: role });
  }
}
