import { FileService } from "./../../../shared/services/file.service";
import { SelectionType } from "./../../../pages/tables/ngxdatatables/ngxdatatables.component";
import { take } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";
import { InstrumentService } from "./../../services/instrument.service";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-ins-provenance",
  templateUrl: "./ins-provenance.component.html",
  styleUrls: ["./ins-provenance.component.scss"],
})
export class InsProvenanceComponent implements OnInit {
  insId;
  subscription;
  insProv;
  onEdit = false;
  provId;

  rows;
  temp = [];
  entries: number = 10;
  selected = [];
  activeRow: any;
  instrument;
  SelectionType = SelectionType;

  uploadModal: BsModalRef;
  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fs: FileService,
    private location: Location
  ) {
    this.insId = this.route.snapshot.paramMap.get("id");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));

    this.subscription = this.InstrumentService.getAllProv(this.insId).subscribe(
      (data) => {
        this.rows = data;
        this.temp = this.rows.map((prop, key) => {
          return {
            ...prop,
            index: key,
          };
        });
        // this.selected = [this.temp[0]];
      }
    );
  }

  ngOnInit(): void {}

  onTableSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.insProv = {};
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  edit(modalDefault: TemplateRef<any>) {
    this.onEdit = true;
    this.insProv = this.selected[0];
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  save() {
    if (this.onEdit) {
      delete this.insProv["index"];
      this.InstrumentService.updateProv(
        this.insProv,
        this.insId,
        this.selected[0].provId
      );
      this.onEdit = false;
      this.defaultModal.hide();
    } else {
      this.InstrumentService.createProv(this.insProv, this.insId);
      this.insProv = {};
      // this.showNotification("success");
      this.defaultModal.hide();
    }
  }

  delete() {
    let dbPath =
      "instrument/" +
      this.insId +
      "/provenance/" +
      this.selected[0].provId +
      "/";
    let storePath =
      "instrument/" +
      this.insId +
      "/provenance/" +
      this.selected[0].provId +
      "/";
    if (
      !confirm("Are you sure you want to delete this provenance and its files?")
    )
      return;
    this.fs.deleteFolder(dbPath, storePath);
  }

  back(): void {
    this.location.back();
  }
}
