import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { BsModalService } from "ngx-bootstrap/modal";
import { SimpleChanges, TemplateRef } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Instrument } from "shared/models/instrument";

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "ins-card",
  templateUrl: "./ins-card.component.html",
  styleUrls: ["./ins-card.component.css"],
})
export class InsCardComponent implements OnInit {
  @Input("data") instrument: Instrument;

  // @Input()
  // set data(instrument: Instrument){
  //   this._instrument = instrument
  // }

  files: File[] = [];

  id;
  filePath;
  ref;
  downloadURL;
  percentage: Observable<number>;
  task: AngularFireUploadTask;
  snapshot: Observable<any>;

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private modalService: BsModalService,
    private store: AngularFireStorage,
    private db: AngularFirestore
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["instrument"]) {
      this.id = changes["instrument"].currentValue.id;
      this.filePath = "/instrument/" + this.id + "/profile";
    }
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.files = [];
    this.percentage = null;
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  uploadImage() {
    this.ref = this.store.ref(this.filePath);

    // The main task
    this.task = this.store.upload(this.filePath, this.files[0]);
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await this.ref.getDownloadURL().toPromise();
        this.db
          .collection("instrument")
          .doc(this.id)
          .update({ profileImg: this.downloadURL });
      })
    );

    this.snapshot.subscribe();
    this.defaultModal.hide();
    //  this.showNotification('success');
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
