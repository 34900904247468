import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ins-media-file",
  templateUrl: "./ins-media-file.component.html",
  styleUrls: ["./ins-media-file.component.scss"],
})
export class InsMediaFileComponent implements OnInit {
  instrument;
  insId;
  mediaId;

  storePath;
  dbPath;

  constructor(private route: ActivatedRoute) {
    this.insId = this.route.snapshot.paramMap.get("id");
    this.mediaId = this.route.snapshot.paramMap.get("mediaId");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));
  }

  ngOnInit() {
    this.dbPath =
      "/instrument/" + this.insId + "/media/" + this.mediaId + "/file/";
    this.storePath =
      "/instrument/" + this.insId + "/media/" + this.mediaId + "/";
  }
}
