import { NotificationService } from "./../../../shared/services/notification.service";
import { CpaService } from "./../../services/cpa.service";
import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { CPA } from "shared/models/cpa";

import { BsModalRef } from "ngx-bootstrap/modal";
import { UserService } from "shared/services/user.service";

@Component({
  selector: "cpa-card",
  templateUrl: "./cpa-card.component.html",
  styleUrls: ["./cpa-card.component.scss"],
})
export class CpaCardComponent implements OnInit {
  @Input("data") cpa: CPA;

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private cpaService: CpaService,
    private notif: NotificationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  update() {
    delete this.cpa["index"];
    this.cpaService.update(this.cpa.id, this.cpa);
    if (this.cpa["status"] == "Approved") {
      this.userService.updateSyfirRole(this.cpa["uid"], "cpr");
    } else {
      this.userService.updateSyfirRole(this.cpa["uid"], "publicUser");
    }

    this.notif.showNotification(
      "success",
      "Content Provider Application Updated"
    );
  }
}
