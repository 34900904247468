import { Routes } from "@angular/router";
import { DashboardComponent } from "./components/dashboard.component";

export const DashboardRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: DashboardComponent
      }
    ]
  }
];
