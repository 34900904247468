import { CpaService } from "./../../services/cpa.service";
import { SelectionType } from "./../../../instrument/components/ins-table/ins-table.component";
import { Component, OnInit, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "cpa-file",
  templateUrl: "./cpa-file.component.html",
  styleUrls: ["./cpa-file.component.scss"],
})
export class CpaFileComponent implements OnInit {
  @Input("data") cpaFiles;

  id;
  rows;
  temp = [];
  entries: number = 10;
  selected = [];
  activeRow: any;
  SelectionType = SelectionType;
  subscription;

  constructor(private CpaService: CpaService) {}

  ngOnInit() {}
}
