import { Observable } from "rxjs";
import { FileService } from "./../../shared/services/file.service";
import { AuthService } from "./../../shared/services/auth.service";
import { InstrumentInsurance } from "./../../shared/models/ins-insurance";
import { InstrumentProv } from "./../../shared/models/ins-prov";
import firebase from "firebase/app";
import { InstrumentMedia } from "./../../shared/models/ins-media";
import { AngularFireStorage } from "@angular/fire/storage";
import { Instrument } from "./../../shared/models/instrument";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class InstrumentService {
  appUser;

  constructor(
    private db: AngularFirestore,
    private store: AngularFireStorage,
    private auth: AuthService,
    private fs: FileService
  ) {
    this.auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
  }

  // create(instrument){
  //   let docRef = this.db.collection('instrument').doc();
  //   docRef.set(instrument);
  //   return docRef.ref.id
  // };

  create(instrument) {
    instrument["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    instrument["createPerson"] = this.appUser.name;
    instrument.makerMiddle
      ? (instrument["maker"] =
          instrument.makerFirst +
          " " +
          instrument.makerMiddle +
          " " +
          instrument.makerLast)
      : (instrument["maker"] =
          instrument.makerFirst + " " + instrument.makerLast);
    // instrument["source"] = "";
    instrument["public"] = true;
    return this.db.collection("instrument").add(instrument);
  }

  getAll() {
    return this.db
      .collection<Instrument>("instrument")
      .valueChanges({ idField: "id" });
  }

  get(instrumentId) {
    return this.db
      .doc<Instrument>("/instrument/" + instrumentId)
      .valueChanges({ idField: "id" });
  }

  update(instrumentId, instrument) {
    instrument["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    instrument["lastModifyPerson"] = this.appUser.name;
    instrument.makerMiddle
      ? (instrument["maker"] =
          instrument.makerFirst +
          " " +
          instrument.makerMiddle +
          " " +
          instrument.makerLast)
      : (instrument["maker"] =
          instrument.makerFirst + " " + instrument.makerLast);
    return this.db.doc("/instrument/" + instrumentId).update(instrument);
  }

  delete(instrumentId) {
    this.db.doc("/instrument/" + instrumentId).delete();
  }

  upload(path, event) {
    this.store.upload(path, event.target.files[0]);
  }

  ///////////////----------------------------Note----------------------------//////////////
  getNote(instrumentId) {
    return this.db
      .collection<InstrumentMedia>("/instrument/" + instrumentId + "/note")
      .valueChanges({ idField: "noteId" });
  }

  createNote(insMedia, insId) {
    let docRef = this.db.collection("instrument/" + insId + "/media/").doc();
    insMedia["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insMedia["createPerson"] = this.appUser.name;
    docRef.set(insMedia);
    return docRef.ref.id;
  }

  updateNote(insMedia, insId, mediaId) {
    insMedia["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insMedia["lastModifyPerson"] = this.appUser.name;
    this.db.doc("instrument/" + insId + "/media/" + mediaId).update(insMedia);
  }

  ///////////////----------------------------Media----------------------------//////////////
  getAllMedia(instrumentId) {
    return this.db
      .collection<InstrumentMedia>("/instrument/" + instrumentId + "/media")
      .valueChanges({ idField: "mediaId" });
  }

  createMedia(insMedia, insId) {
    let docRef = this.db.collection("instrument/" + insId + "/media/").doc();
    insMedia["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insMedia["createPerson"] = this.appUser.name;
    docRef.set(insMedia);
    return docRef.ref.id;
  }

  updateMedia(insMedia, insId, mediaId) {
    insMedia["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insMedia["lastModifyPerson"] = this.appUser.name;
    this.db.doc("instrument/" + insId + "/media/" + mediaId).update(insMedia);
  }

  ///////////////----------------------------Provenance----------------------------//////////////
  getAllProv(insId) {
    return this.db
      .collection<InstrumentProv>("/instrument/" + insId + "/provenance")
      .valueChanges({ idField: "provId" });
  }

  createProv(insProv, insId) {
    let docRef = this.db
      .collection("instrument/" + insId + "/provenance/")
      .doc();
    insProv["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insProv["createPerson"] = this.appUser.name;
    docRef.set(insProv);
    return docRef.ref.id;
  }

  updateProv(insProv, insId, provId) {
    insProv["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insProv["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/provenance/" + provId)
      .update(insProv);
  }

  ///////////////----------------------------Insurance----------------------------//////////////
  getAllInsurance(insId) {
    return this.db
      .collection<InstrumentInsurance>("/instrument/" + insId + "/insurance")
      .valueChanges({ idField: "insuranceId" });
  }

  createInsurance(insInsurance, insId) {
    let docRef = this.db
      .collection("instrument/" + insId + "/insurance/")
      .doc();
    insInsurance["createTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insInsurance["createPerson"] = this.appUser.name;
    docRef.set(insInsurance);
    return docRef.ref.id;
  }

  updateInsurance(insInsurance, insId, insuranceId) {
    insInsurance["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insInsurance["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/insurance/" + insuranceId)
      .update(insInsurance);
  }

  ///////////////----------------------------Loan----------------------------//////////////
  getAllLoan(insId) {
    return this.db
      .collection<InstrumentMedia>("/instrument/" + insId + "/loan")
      .valueChanges({ idField: "loanId" });
  }

  createLoan(insLoan, insId) {
    let docRef = this.db.collection("instrument/" + insId + "/loan/").doc();
    insLoan["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insLoan["createPerson"] = this.appUser.name;
    docRef.set(insLoan);
    return docRef.ref.id;
  }

  updateLoan(insLoan, insId, loanId) {
    insLoan["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insLoan["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/loan/" + loanId)
      .update(insLoan);
  }

  ///////////////----------------------------Ownership----------------------------//////////////
  getAllOwn(insId) {
    return this.db
      .collection<InstrumentMedia>("/instrument/" + insId + "/ownership")
      .valueChanges({ idField: "ownId" });
  }

  createOwn(insOwn, insId) {
    let docRef = this.db
      .collection("instrument/" + insId + "/ownership/")
      .doc();
    insOwn["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insOwn["createPerson"] = this.appUser.name;
    docRef.set(insOwn);
    return docRef.ref.id;
  }

  updateOwn(insOwn, insId, ownId) {
    insOwn["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insOwn["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/ownership/" + ownId)
      .update(insOwn);
  }

  ///////////////----------------------------Alpha Report----------------------------//////////////
  getAllAlphaReport(insId) {
    return this.db
      .collection<InstrumentMedia>("/instrument/" + insId + "/alphaReport")
      .valueChanges({ idField: "alphaReportId" });
  }

  createAlphaReport(insAlphaReport, insId) {
    let docRef = this.db
      .collection("instrument/" + insId + "/alphaReport/")
      .doc();
    insAlphaReport["createTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insAlphaReport["createPerson"] = this.appUser.name;
    docRef.set(insAlphaReport);
    return docRef.ref.id;
  }

  updateAlphaReport(insAlphaReport, insId, alphaReportId) {
    insAlphaReport["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insAlphaReport["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/alphaReport/" + alphaReportId)
      .update(insAlphaReport);
  }

  ///////////////----------------------------Beta Report----------------------------//////////////
  getAllBetaReport(insId) {
    return this.db
      .collection<InstrumentMedia>("/instrument/" + insId + "/betaReport")
      .valueChanges({ idField: "betaReportId" });
  }

  createBetaReport(insBetaReport, insId) {
    let docRef = this.db
      .collection("instrument/" + insId + "/betaReport/")
      .doc();
    insBetaReport["createTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insBetaReport["createPerson"] = this.appUser.name;
    docRef.set(insBetaReport);
    return docRef.ref.id;
  }

  updateBetaReport(insBetaReport, insId, betaReportId) {
    insBetaReport["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insBetaReport["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/betaReport/" + betaReportId)
      .update(insBetaReport);
  }

  ///////////////----------------------------Repair----------------------------//////////////
  getAllRepair(insId) {
    return this.db
      .collection<InstrumentInsurance>("/instrument/" + insId + "/repair")
      .valueChanges({ idField: "repairId" });
  }

  createRepair(insRepair, insId) {
    let docRef = this.db.collection("instrument/" + insId + "/repair/").doc();
    insRepair["createTime"] = firebase.firestore.FieldValue.serverTimestamp();
    insRepair["createPerson"] = this.appUser.name;
    docRef.set(insRepair);
    return docRef.ref.id;
  }

  updateRepair(insRepair, insId, repairId) {
    insRepair["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insRepair["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/repair/" + repairId)
      .update(insRepair);
  }
  ///////////////----------------------------Appraisal----------------------------//////////////
  getAllAppraisal(insId) {
    return this.db
      .collection<InstrumentInsurance>("/instrument/" + insId + "/appraisal")
      .valueChanges({ idField: "appraisalId" });
  }

  createAppraisal(insAppraisal, insId) {
    let docRef = this.db
      .collection("instrument/" + insId + "/appraisal/")
      .doc();
    insAppraisal["createTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insAppraisal["createPerson"] = this.appUser.name;
    docRef.set(insAppraisal);
    return docRef.ref.id;
  }

  updateAppraisal(insAppraisal, insId, appraisalId) {
    insAppraisal["lastModifyTime"] =
      firebase.firestore.FieldValue.serverTimestamp();
    insAppraisal["lastModifyPerson"] = this.appUser.name;
    return this.db
      .doc("instrument/" + insId + "/appraisal/" + appraisalId)
      .update(insAppraisal);
  }

  ///////////////----------------------------Deploy----------------------------//////////////

  getLastDeploy() {
    return this.db.collection("deploy").doc("aws-amplify");
  }

  deploy() {
    return this.db
      .doc("/deploy/aws-amplify")
      .update({ time: firebase.firestore.FieldValue.serverTimestamp() });
  }
}
