import { AngularFirestore } from '@angular/fire/firestore';
import { Organization } from 'shared/models/organization';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, debounceTime, map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  
  // organizations: Organization[] = [
  //   { name: 'Event 1', email: 'apple', phone: 1234567, street:"street A", city:"Princeton", state:"NJ", country:"usa"},
  //   { name: 'Event 2', email: 'pen', phone: 1234567, street:"street A", city:"Princeton", state:"NJ", country:"usa"},
  //   { name: 'Event 3', email: 'apple pen', phone: 1234567, street:"street A", city:"Princeton", state:"NJ", country:"usa"},
  //   { name: 'Event 4', email: 'apple', phone: 1234567, street:"street A", city:"Princeton", state:"NJ", country:"usa"},
  //   { name: 'Event 5', email: 'pineapple', phone: 1234567, street:"street A", city:"Princeton", state:"NJ", country:"usa"},
  //   { name: 'Event 6', email: 'pinapple pen', phone: 1234567, street:"street A", city:"Princeton", state:"NJ", country:"usa"},
  // ];


  // private dataSource = new BehaviorSubject(this.organizations);
  // orgData = this.dataSource.asObservable();



  constructor(private db: AngularFirestore) {

   }

  create(organization){
    return this.db.collection('organization').add(organization);
  }

  getAll(){
    return this.db.collection<Organization>('organization').valueChanges({ idField: 'id' });
  }

  get(organizationId){
    return this.db.doc<Organization>('/organization/'+organizationId).valueChanges();
  }

  update(organizationId, organization){
    return this.db.doc('/organization/'+organizationId).update(organization);
  }

  delete(organizationId){
    return this.db.doc('/organization/'+organizationId).delete();
  }



  // private organizations = [];

  // fetchOrgs(){

  //   if (this.organizations && this.organizations.length) {
  //     return of(this.organizations)
  //   } else {
  //     return this.db.collection('organization', ref => {
  //       return ref.orderBy('name')
  //     }).valueChanges({ idField: 'id' }).pipe(
  //         tap((organizations) => this.organizations = organizations)
  //       );
  //   }

  // }



}
