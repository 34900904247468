import { AuthService } from 'shared/services/auth.service';
import { AppUser } from 'shared/models/app-user';
import { Organization } from 'shared/models/organization';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from './../../services/organization.service';
import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';




@Component({
  selector: 'app-organization-form',
  templateUrl: './org-form.component.html',
  styleUrls: ['./org-form.component.css']
})
export class OrgFormComponent implements OnInit {

  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  focus5;
  focus6;
  focus7;
  

  appUser: AppUser;
  organization:Organization=<Organization>{ };
  id;


  constructor(
    private auth: AuthService,
    private OrganizationService: OrganizationService,
    private route: ActivatedRoute,
    private router:Router,){ 
      auth.appUser$.subscribe(appUser => this.appUser = appUser);
      this.id = this.route.snapshot.paramMap.get('id');
      if (this.id) this.OrganizationService.get(this.id).pipe(take(1)).subscribe(data=>this.organization=data);
    
    }

  save(organization){
    if (this.id){ 
      organization['lastModifyTime'] = firebase.firestore.FieldValue.serverTimestamp();
      organization['lastModifyPerson'] = this.appUser.name
      this.OrganizationService.update(this.id,organization);
    }else{ 
      organization['createTime'] = firebase.firestore.FieldValue.serverTimestamp();
      organization['createPerson'] = this.appUser.name
      organization['lastModifyTime'] = firebase.firestore.FieldValue.serverTimestamp();
      organization['lastModifyPerson'] = this.appUser.name
      this.OrganizationService.create(organization);
    }this.router.navigate(['organization']);



  }

  delete(){
    if (!confirm('Are you sure you want to delete this organization?')) return;
    this.OrganizationService.delete(this.id);
    this.router.navigate(['organization']);
    
  }
  

  ngOnInit(): void {
  }

}
