import { AuthService } from "./../../shared/services/auth.service";
import { CpaFile } from "./../../shared/models/cpa-file";
import { CPA } from "./../../shared/models/cpa";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class CpaService {
  appUser;

  constructor(private db: AngularFirestore, private auth: AuthService) {
    this.auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
  }

  getAll() {
    return this.db.collection<CPA>("cpa").valueChanges({ idField: "id" });
  }

  // getAllFile(cpaId) {
  //   return this.db
  //     .collection<CpaFile>("/cpa/" + cpaId + "/file")
  //     .valueChanges({ idField: "id" });
  // }

  getFile(cpaId) {
    let fileList = [];
    this.db
      .collection("/cpa/" + cpaId + "/file")
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          fileList.push(doc.data());
        });
      });

    return fileList;
  }

  update(cpaId, cpa) {
    cpa["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp();
    cpa["lastModifyPerson"] = this.appUser.name;
    return this.db.doc("/cpa/" + cpaId).update(cpa);
  }

  // async getFile(cpaId) {

  //   const snapshot = await this.db
  //     .collection("/cpa/" + cpaId + "/file")
  //     .get()
  //     .toPromise();
  //   return snapshot.docs.map((doc) => doc.data());
  // }
}
