import { AuthService } from './../../../shared/services/auth.service';
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  constructor(private auth:AuthService) {}

  login() {
    this.auth.login();
  }

  signIn(email, password){
    this.auth.signIn(email, password);
  }


  ngOnInit() {}
}
