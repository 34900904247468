import { CpaService } from "./../../services/cpa.service";
import { SelectionType } from "./../../../instrument/components/ins-table/ins-table.component";
import { Component, OnInit, OnDestroy, TemplateRef } from "@angular/core";
import { CPA } from "./../../../shared/models/cpa";
import { Subscription } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "cpa-table",
  templateUrl: "./cpa-table.component.html",
  styleUrls: ["./cpa-table.component.scss"],
})
export class CpaTableComponent implements OnInit {
  entries: number = 10;
  selected: CPA[] = [];
  temp = [];
  activeRow: any;
  files = [];

  subscription: Subscription;
  rows: CPA[] = [];

  SelectionType = SelectionType;

  constructor(private CpaService: CpaService) {
    this.subscription = this.CpaService.getAll().subscribe((data) => {
      this.rows = data;
      this.temp = this.rows.map((prop, key) => {
        return {
          ...prop,
          index: key,
        };
      });
      this.selected = [this.temp[0]];
      this.files = this.CpaService.getFile(this.selected[0].id);
    });
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  onTableSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.files = this.CpaService.getFile(this.selected[0].id);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
