import { take } from "rxjs/operators";
import { ProtocolService } from "./../../services/protocol.service";
import { NotificationService } from "shared/services/notification.service";
import { Router } from "@angular/router";
import { Protocol } from "./../../../shared/models/protocol";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "protocol-editor",
  templateUrl: "./protocol-editor.component.html",
  styleUrls: ["./protocol-editor.component.scss"],
})
export class ProtocolEditorComponent implements OnInit {
  editorContent;
  protocol: Protocol = <Protocol>{};

  constructor(
    private protocolService: ProtocolService,
    private router: Router,
    private notif: NotificationService
  ) {
    this.protocolService
      .get()
      .pipe(take(1))
      .subscribe((data) => (this.protocol = data));
  }

  ngOnInit() {
    // var quill = new Quill("#quill", {
    // });
    // this.editorContent = quill.root.innerHTML;
  }

  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        // ["code-block"],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ direction: "rtl" }], // text direction

        // [{ size: ["small", false, "large", "huge"] }], // custom dropdown

        //[{ 'font': [] }],
        // [{ align: [] }],

        ["clean"], // remove formatting button

        // ["link"],
        ["link", "image"],
      ],
    },
  };

  save() {
    this.protocolService.update(this.protocol);
    this.notif.showNotification("success", "Protocol Updated");

    this.router.navigate(["cpa"]);
  }
}
