import { AuthService } from "shared/services/auth.service";
import { Protocol } from "./../../shared/models/protocol";
import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class ProtocolService {
  appUser;

  constructor(private db: AngularFirestore, private auth: AuthService) {
    this.auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
  }

  get() {
    return this.db
      .collection<Protocol>("protocol")
      .doc("iTJWckCORHyZPkyurW5u")
      .valueChanges({ idField: "id" });
  }

  update(data) {
    data["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp();
    data["lastModifyPerson"] = this.appUser.name;
    return this.db.doc("/protocol/iTJWckCORHyZPkyurW5u").update(data);
  }
}
