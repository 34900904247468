import { NotificationService } from "shared/services/notification.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TemplateRef } from "@angular/core";
import { AppUser } from "shared/models/app-user";
import { Instrument } from "shared/models/instrument";
import { take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { InstrumentService } from "./../../services/instrument.service";
import { Component, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: "ins-form",
  templateUrl: "./ins-form.component.html",
  styleUrls: ["./ins-form.component.css"],
})
export class InsFormComponent implements OnInit {
  appUser: AppUser;
  instrument: Instrument = <Instrument>{};
  id;

  // editorContent;
  // quill;

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    private modalService: BsModalService,
    private notif: NotificationService
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id) {
      this.InstrumentService.get(this.id)
        .pipe(take(1))
        .subscribe((data) => (this.instrument = data));
    }
  }

  save() {
    if (this.id) {
      this.InstrumentService.update(this.id, this.instrument);
      this.notif.showNotification("success", "Instrument Updated");
    } else {
      this.InstrumentService.create(this.instrument);
      this.notif.showNotification("success", "New Instrument Created");
    }
    this.router.navigate(["instrument"]);
  }

  delete() {
    if (!confirm("Are you sure you want to delete this instrument?")) return;
    this.InstrumentService.delete(this.id);
    this.router.navigate(["instrument"]);
  }

  ngOnInit() {
    // this.quill = new Quill(this.quillConfig);
    // this.editorContent = this.quill.root.innerHTML;
  }

  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        // ["code-block"],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ direction: "rtl" }], // text direction

        // [{ size: ["small", false, "large", "huge"] }], // custom dropdown

        //[{ 'font': [] }],
        // [{ align: [] }],

        ["clean"], // remove formatting button

        // ["link"],
        ["link", "image"],
      ],
    },

    // clipboard: {
    //   matchVisual: false,
    // },

    // keyboard: {
    //   bindings: {
    //     shiftEnter: {
    //       key: 13,
    //       shiftKey: true,
    //       handler: (range, context) => {
    //         // Handle shift+enter
    //         console.log("shift+enter");
    //       },
    //     },
    //     enter: {
    //       key: 13,
    //       handler: (range, context) => {
    //         console.log("enter");
    //         return true;
    //       },
    //     },
    //   },
    // },
  };

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }
}
