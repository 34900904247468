import { AuthService } from "./../../services/auth.service";
import { Location } from "@angular/common";
import { finalize } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireUploadTask } from "@angular/fire/storage";
import { Input, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FileService } from "./../../services/file.service";
import { SelectionType } from "./../../../pages/tables/ngxdatatables/ngxdatatables.component";
import { AppUser } from "./../../models/app-user";
import { Observable, Subscription } from "rxjs";
import { Component, OnInit } from "@angular/core";
import firebase from "firebase/app";

@Component({
  selector: "file-browser",
  templateUrl: "./file-browser.component.html",
  styleUrls: ["./file-browser.component.scss"],
})
export class FileBrowserComponent implements OnInit {
  @Input("storePath") storePath: string;
  @Input("dbPath") dbPath: string;

  onEdit = false;

  appUser: AppUser;

  subscription: Subscription;
  rows;
  temp;
  selected = [];
  uploadModal: BsModalRef;
  editModal: BsModalRef;
  linkModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  files: File[] = [];
  file;
  ref;
  task: AngularFireUploadTask;
  downloadURL;
  percentage;
  snapshot: Observable<any>;

  activeRow: any;
  entries: number = 10;
  SelectionType = SelectionType;
  uploadDisabled = false;

  constructor(
    private FileService: FileService,
    private modalService: BsModalService,
    private db: AngularFirestore,
    private store: AngularFireStorage,
    private location: Location,
    private auth: AuthService
  ) {
    auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
  }

  onTableSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  openUploadModal(modalDefault: TemplateRef<any>) {
    this.files = [];
    this.uploadDisabled = false;
    this.percentage = 0;
    this.uploadModal = this.modalService.show(modalDefault, this.default);
  }

  openLinkModal(modalEdit: TemplateRef<any>) {
    this.file = {};
    this.editModal = this.modalService.show(modalEdit, this.default);
  }

  edit(modalEdit: TemplateRef<any>) {
    this.onEdit = true;
    this.file = this.selected[0];
    this.editModal = this.modalService.show(modalEdit, this.default);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.FileService.getAllFile(this.dbPath).subscribe(
      (data) => {
        this.rows = data;
        this.temp = this.rows.map((prop, key) => {
          return {
            ...prop,
            index: key,
          };
        });
        this.selected = [this.temp[0]];
      }
    );
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  upload() {
    for (let i = 0; i < this.files.length; i++) {
      this.uploadFile(this.files[i]);
    }
    this.uploadDisabled = true;
  }

  uploadFile(file) {
    let fileName = file.name;
    let fileType = file.type;
    let fileSize = file.size;

    let randId = Math.random().toString(36).substr(2, 10);
    let path = this.storePath + fileName + "-" + randId;
    let ref = this.store.ref(path);
    // The main task
    this.task = this.store.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.db.collection(this.dbPath).add({
          name: fileName,
          type: fileType,
          size: fileSize,
          downloadURL: this.downloadURL,
          public: true,
          path,
          createPerson: this.appUser.name,
          createTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      })
    );

    this.snapshot.subscribe();
  }

  update() {
    delete this.file["index"];
    this.FileService.updateFile(this.file, this.dbPath + this.file.fileId);
    this.editModal.hide();
  }

  save() {
    if (this.onEdit) {
      delete this.file["index"];
      this.FileService.updateFile(this.file, this.dbPath + this.file.fileId);
      this.editModal.hide();
      this.onEdit = false;
    } else {
      // this.db.collection(this.dbPath).add({
      //   name: this.file.name,
      //   url: this.file.url,
      //   note: this.file.note,
      //   createPerson: this.appUser.name,
      //   createTime: firebase.firestore.FieldValue.serverTimestamp(),
      // });
      this.FileService.creatLinkFile(this.file, this.dbPath);
      this.editModal.hide();
    }
  }

  delete() {
    if (!confirm("Are you sure you want to delete this file?")) return;
    this.FileService.deleteFile(
      this.dbPath + this.selected[0].fileId,
      this.selected[0].path
    );
  }

  back(): void {
    this.location.back();
  }
}
