import { FileService } from "./../../../shared/services/file.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SelectionType } from "./../../../pages/tables/ngxdatatables/ngxdatatables.component";
import { take } from "rxjs/operators";
import { AuthService } from "./../../../shared/services/auth.service";
import { InstrumentService } from "./../../services/instrument.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { Location } from "@angular/common";
import { NotificationService } from "shared/services/notification.service";

@Component({
  selector: "ins-media",
  templateUrl: "./ins-media.component.html",
  styleUrls: ["./ins-media.component.scss"],
})
export class InsMediaComponent implements OnInit {
  appUser;
  insId;
  subscription;
  insMedia;
  onEdit = false;
  disableSubmit = false;
  mediaId;

  rows;
  temp = [];
  entries: number = 10;
  selected = [];
  activeRow: any;
  instrument;
  SelectionType = SelectionType;

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered",
  };

  constructor(
    private auth: AuthService,
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fs: FileService,
    private location: Location,
    private notif: NotificationService
  ) {
    auth.appUser$.subscribe((appUser) => (this.appUser = appUser));
    this.insId = this.route.snapshot.paramMap.get("id");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));

    this.subscription = this.InstrumentService.getAllMedia(
      this.insId
    ).subscribe((data) => {
      this.rows = data;
      this.temp = this.rows.map((prop, key) => {
        return {
          ...prop,
          index: key,
        };
      });
      // this.selected = [this.temp[0]];
    });
  }

  ngOnInit(): void {}

  onTableSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.disableSubmit = false;
    this.insMedia = {};
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  edit(modalDefault: TemplateRef<any>) {
    this.disableSubmit = false;
    this.onEdit = true;
    this.insMedia = this.selected[0];
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  save() {
    if (this.onEdit) {
      this.disableSubmit = true;
      delete this.insMedia["index"];
      this.InstrumentService.updateMedia(
        this.insMedia,
        this.insId,
        this.selected[0].mediaId
      );
      this.onEdit = false;
      this.defaultModal.hide();
    } else {
      this.disableSubmit = true;
      this.InstrumentService.createMedia(this.insMedia, this.insId);
      this.defaultModal.hide();
      this.notif.showNotification("success", "Entry Created");
      this.insMedia = {};
    }
  }

  delete() {
    let dbPath =
      "instrument/" + this.insId + "/media/" + this.selected[0].mediaId + "/";
    let storePath =
      "instrument/" + this.insId + "/media/" + this.selected[0].mediaId + "/";
    if (!confirm("Are you sure you want to delete this loan and its files?"))
      return;
    this.fs.deleteFolder(dbPath, storePath);
  }

  back(): void {
    this.location.back();
  }

  // selectFileType(event) {
  //   let selected = event.target.value;
  //   switch (selected) {
  //     case "image": {
  //       this.fileType = "image";
  //       break;
  //     }

  //     case "video": {
  //       this.fileType = "video";
  //       break;
  //     }

  //     case "audio": {
  //       this.fileType = "audio";
  //       break;
  //     }

  //     case "document": {
  //       this.fileType = "document";
  //       break;
  //     }

  //     case "link": {
  //       this.fileType = "link";
  //       break;
  //     }

  //     default: {
  //       break;
  //     }
  //   }
  // }
}
