import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { InstrumentService } from "app/instrument/services/instrument.service";

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ins-alpha-report-file",
  templateUrl: "./ins-alpha-report-file.component.html",
  styleUrls: ["./ins-alpha-report-file.component.scss"],
})
export class InsAlphaReportFileComponent implements OnInit {
  insId;
  alphaReportId;

  filePath;
  storePath;
  dbPath;

  constructor(
    private InstrumentService: InstrumentService,
    private route: ActivatedRoute
  ) {
    this.insId = this.route.snapshot.paramMap.get("id");
    this.alphaReportId = this.route.snapshot.paramMap.get("alphaReportId");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));
  }

  ngOnInit() {
    this.dbPath =
      "/instrument/" +
      this.insId +
      "/alphaReport/" +
      this.alphaReportId +
      "/file/";
    this.storePath =
      "/instrument/" + this.insId + "/alphaReport/" + this.alphaReportId + "/";
  }
}
