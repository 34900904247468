import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { InstrumentService } from "app/instrument/services/instrument.service";

@Component({
  selector: "ins-beta-report-file",
  templateUrl: "./ins-beta-report-file.component.html",
  styleUrls: ["./ins-beta-report-file.component.scss"],
})
export class InsBetaReportFileComponent implements OnInit {
  insId;
  betaReportId;

  filePath;
  storePath;
  dbPath;

  constructor(private route: ActivatedRoute) {
    this.insId = this.route.snapshot.paramMap.get("id");
    this.betaReportId = this.route.snapshot.paramMap.get("betaReportId");

    // if (this.insId)
    //   this.InstrumentService.get(this.insId)
    //     .pipe(take(1))
    //     .subscribe((data) => (this.instrument = data));
  }

  ngOnInit() {
    this.dbPath =
      "/instrument/" +
      this.insId +
      "/betaReport/" +
      this.betaReportId +
      "/file/";
    this.storePath =
      "/instrument/" + this.insId + "/betaReport/" + this.betaReportId + "/";
  }
}
