import { Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";

export const CoreRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "login",
        component: LoginComponent
      }
    ]
  }
];
